<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">处理电话查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="首航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="depCode"
                :arrCity="arrCode"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSearch"> 查询 </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :columns="columns"
            :data-source="data"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            @change="onChange"
          >
            <template slot="org" slot-scope="text">
              {{ text | airCity }}
            </template>
            <template slot="dst" slot-scope="text">
              {{ text | airCity }}
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'
import { fetchLineContact } from '@/api/notice'
import { airCity } from '@/filters/flight'

const columns = [
  {
    title: '出发机场',
    dataIndex: 'org',
    scopedSlots: { customRender: 'org' }
  },
  {
    title: '目的机场',
    dataIndex: 'dst',
    scopedSlots: { customRender: 'dst' }
  },
  {
    title: '大区管理员',
    dataIndex: 'mgr'
  },
  {
    title: '联系电话',
    dataIndex: 'mobile'
  },
  {
    title: '部门',
    dataIndex: 'dept'
  }
]

export default {
  name: 'advicePhoneSearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [
    tableBaseMixin
  ],
  data () {
    return {
      data: [],
      columns,
      loading: false,
      depCode: '',
      arrCode: '',
      pageOptions: {
        current: 1,
        pageSize: 10,
        total: 1
      }
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { depCode, arrCode, pageOptions } = this
        this.loading = true
        const data = await fetchLineContact({
          org: depCode,
          dst: arrCode,
          size: pageOptions.pageSize,
          page: pageOptions.current - 1
        })

        this.data = data.content
        this.pageOptions.total = data.totalElements
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    onChange (pagination) {
      this.pageOptions = pagination
      this.fetch()
    },
    onSearch () {
      this.pageOptions.current = 1
      this.fetch()
    },
    onDepChange (city) {
      this.depCode = city
    },
    onArrChange (city) {
      this.arrCode = city
    }
  },
  filters: {
    airCity
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
